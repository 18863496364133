<template>
  <mobile-screen :header="true" screen-class="gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("sync", "sync", "sync") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <div v-if="globalDateFormat" class="clebex-item-range-cta">
                {{ displayDate(selectedDateFromParsed) }}
              </div>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </li>
            <li class="clebex-item-range to">
              <div v-if="globalDateFormat" class="clebex-item-range-cta">
                {{ displayDate(selectedDateToParsed) }}
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="clebex-item-section-item overflow-hidden">
        <div class="clebex-item-content-wrapper">
          <select-date-range
            :selectDateFn="selectDate"
            :show-date-picker="true"
            :dates-to-watch="[selectedDateFrom, selectedDateTo]"
            :selected-dates="[selectedDateFrom, selectedDateTo]"
            :cyclic-year="true"
          />
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill"
      v-if="resourceCalendars && resourceCalendars.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceCalendar in resourceCalendars"
        :key="resourceCalendar.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="toEventList(resourceCalendar)"
        >
          <span
            class="label"
            v-if="
              resourceCalendar.level_parents &&
                resourceCalendar.level_parents.length
            "
          >
            <span class="text">
              <span class="highlight">{{ `${resourceCalendar.name}, ` }}</span>
              {{ resourceCalendar.level_parents.join(", ") }}
            </span>
          </span>
          <span class="label" v-else>
            <span class="text">
              <span class="highlight">{{ `${resourceCalendar.name} ` }}</span>
            </span>
          </span>
          <span class="follow-up-icons">
            <button class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right"></icon>
            </button>
          </span>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu sync-footer">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn active" @click="toEventList(false)">
              {{ displayLabelName("sync", "sync", "sync-init") }}
            </button>
          </li>
          <li class="action">
            <button class="action-btn" @click="downloadReport">
              <icon icon="#cx-men1-download-sample" />
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import SelectDateRange from "@/components/global/SelectDateRange";
import dayjs from "dayjs";
import { mapActions, mapGetters, mapState } from "vuex";
import { formatDate } from "@/services/helpers";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";

export default {
  name: "Sync",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange
  },
  data() {
    return {
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null
    };
  },
  computed: {
    ...mapState("resource", ["resourceCalendars"]),
    ...mapGetters("settings", ["globalDateFormat"])
  },
  created() {
    this.setInitialValues();
    this.getResourceCalendars();
  },
  methods: {
    ...mapActions("resource", ["getResourceCalendars", "getResourceEvents"]),
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].displayDate;
        this.selectedDateEventFrom = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
        this.selectedDateEventFrom = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
        this.selectedDateEventTo = dates[1].eventDate;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
        this.selectedDateEventTo = null;
      }
    },
    setInitialValues() {
      this.selectedDateFrom = DateTime.now().toFormat("yyyy-dd-MM");
      this.selectedDateTo = DateTime.now()
        .plus({ days: 14 })
        .toFormat("yyyy-dd-MM");
      this.selectedDateFromParsed = dayjs().format("D MMMM YYYY");
      this.selectedDateToParsed = dayjs()
        .add(14, "day")
        .format("D MMMM YYYY");
      this.selectedDateEventFrom = dayjs().format("YYYY-MM-DD");
      this.selectedDateEventTo = dayjs()
        .add(14, "day")
        .format("YYYY-MM-DD");
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("sync", "sync", "select-date");
      }
      return formatDate(date, this.globalDateFormat, true);
    },
    toEventList(resource) {
      const eventDetails = [
        {
          date_from: this.selectedDateEventFrom,
          date_to: dayjs()
            .add(10, "year")
            .format("YYYY-MM-DD")
        },
        this.displayLabelName("sync", "sync", "sync-init")
      ];
      if (resource) {
        eventDetails[0].email = resource.email;
        eventDetails[0].date_to = this.selectedDateEventTo
          ? this.selectedDateEventTo
          : this.selectedDateEventFrom;
        eventDetails[1] = resource.name;
      }
      this.getResourceEvents(eventDetails);
    },
    downloadReport() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(apiEndpoints.company.declarationInitials, {
          exposedHeaders: ["Content-Disposition"]
        })
        .then(response => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"]
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = "SyncReport.csv";
          if (contentDispositionHeader) {
            const headerFileName = this.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
</script>
